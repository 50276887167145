<template>
  <header class="header" :class="{ 'header--black': showBlackHeader }">
    <div
      class="flex align-items-center justify-content-center container--limited"
    >
      <div class="col-auto header-item">
        <WsDrawsLogoPetloveWhite v-if="showBlackHeader" class="header-image" />
        <WsDrawsLogoPetlove v-else class="header-image" />
      </div>
      <div class="header-item">
        <CaIcon name="map-marker" class="col-auto" />
          <div class="zipcode" :class="{ 'zipcode--black': showBlackHeader }">
            <ClientOnly fallback="Informar CEP">
              <p
                v-show="hasZipcode"
                class="font-body-xs font-regular color-neutral-main"
              >
                Ofertas para:
              </p>
              <button
                v-cloak
                aria-label="Informar cep"
                class="header-item__text reset-link grey cursor-pointer"
                :class="{ 'header-item__text--black': showBlackHeader }"
                @click.prevent="handleCaDialog"
              >
                {{ hasZipcode ? zipcode : 'Informar CEP' }}
              </button>
            </ClientOnly>
          </div>
        </div>
	    <div
        v-if="showZipcodeHeaderInfo"
        v-cloak
        class="modal-container"
      >
        <label for="modal-toggle" />
        <div
          class="modal-content"
          tabindex="0"
        >
          <div class="triangle"/>
          <label class="modal-close" for="modal-toggle" @click="closeAutocompleteDialog">&#x2715;</label>
          <h2 class="font-bold font-body color-neutral-darkest mt-2">Identificamos que você está nessa localização</h2>
          <p class="font-body font-regular color-neutral-darkest">Informe seu CEP correto para acessar as </br> melhores ofertas para a sua região</p>
        </div>
      </div>
      <div class="header-search">
        <div class="header-search__container">
          <WsHeaderDesktopSearch />
        </div>
      </div>

      <!-- Repet -->
      <a class="header-item" href="/repet">
        <CaIcon
          name="subscription"
          class="header-item__subscription-icon mr-4"
          :color="showBlackHeader ? 'white' : null"
          :class="{ 'header-item__subscription-icon--black': showBlackHeader }"
        />
        <div class="header-item__text" :class="{ 'header-item__text--black': showBlackHeader }">Compras recorrentes</div>
      </a>

      <!-- My orders -->
      <a
        href="/minha-conta/pedidos"
        class="header-item header-item--orders"
        @click="clickMenuEvent('meus-pedidos')"
      >
        <CaIcon name="box" :color="showBlackHeader ? 'white' : null" class="mr-4 header-item__order-icon" :class="{ 'header-item__order-icon--black': showBlackHeader }" />
        <div class="header-item__text" :class="{ 'header-item__text--black': showBlackHeader }">Meus pedidos</div>
      </a>

      <!-- Notifications -->
      <div
        v-if="showNotifications"
        class="header-item header-item__icon-spacing"
      >
        <template v-if="isMounted && isLogged">
          <div
            class="header-item__bell-container"
            @mouseenter.once="setNotifications()"
          >
            <CaIcon
              name="notification"
              class="header-item__bell-icon"
              :class="{ 'header-item__bell-icon--black': showBlackHeader }"
            />
          </div>

          <div
            id="notification-header"
            class="header-dropdown header-dropdown--wide header-notification scrollbar"
            :class="{ 'header-notification__empty': !hasNotifications }"
          >
            <div>
              <div v-if="hasNotifications" class="font-title-xs font-bold mb-5">
                Notificações
              </div>
            </div>
          </div>
        </template>
      </div>

      <!-- Cart Icon -->
      <WsHeaderCartLink class="ml-5" @click="clickMenuEvent('carrinho')" />

      <!-- User Information -->
      <div class="header-item__user-bg header-item--user ml-28" :class="{ 'header-item__user-bg--black': showBlackHeader }">
        <div class="header-item justify-content-end">
          <div
            v-if="!isMounted || isLoadingOneTapLogin || getLoadingSingleSignOn"
            class="full-width flex justify-content-center"
          >
            <CaCircularProgress :color="showBlackHeader ? 'neutral' : null" />
          </div>
          <!-- User is logged -->
          <div
            v-else-if="isMounted && isLogged"
            class="header-item__user row no-gutters align-items-center" :class="{ 'header-item__user--black': showBlackHeader }"
          >
            <div class="col">
              <div class="flex align-items-center">
                <img
                  v-if="firstPet.photo_thumb_url"
                  :src="firstPet.photo_thumb_url"
                  class="header-item__thumb"
                  alt="Foto do pet"
                >
                <div v-else class="header-item__thumb">
                  <span
                    class="header-item__thumb-letter font-bold font-title-xs color-primary"
                  >
                    {{ userFirstLetter }}
                  </span>
                </div>
                <div class="header-item__title">
                  {{ firstPet.name || user.name }}
                  <div
                    v-if="
                      userPublic.wallet &&
                      userPublic.wallet.total_with_vouchers !== '0.0'
                    "
                    class="font-body-s"
                  >
                    Saldo:
                    <span :class="{ 'color-white': showBlackHeader, 'color-primary': !showBlackHeader }">
                      {{ userPublic.wallet.display_total_with_vouchers }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto ml-3 line-height-0">
              <CaIcon name="chevron-bottom" />
            </div>
          </div>

          <!-- User not logged -->
          <a
            v-else
            class="header-item__user row no-gutters align-items-center" :class="{ 'header-item__user--black': showBlackHeader }"
            href="/entrar"
            @click="clickMenuEvent('entrar')"
          >
            <div class="col text-center">Entrar | Cadastrar</div>
            <div class="col-auto ml-3 line-height-0">
              <CaIcon name="chevron-bottom" />
            </div>
          </a>

          <!-- User info dropdown -->
          <ul class="header-dropdown">
            <li class="mb-1">
              <a
                class="header-dropdown__item row align-items-center"
                href="/minha-conta/favoritos"
              >
                <CaIcon name="heart" class="col-auto" />
                <div class="col">Favoritos</div>
              </a>
            </li>
            <li class="mb-1">
              <a
                class="header-dropdown__item row align-items-center"
                href="/minha-conta/dados"
              >
                <CaIcon name="user" class="col-auto" />
                <div class="col">Meus dados</div>
              </a>
            </li>
            <li class="mb-1">
              <a
                class="header-dropdown__item row align-items-center"
                href="/minha-conta/enderecos"
              >
                <CaIcon name="map-marker" class="col-auto" :class="{ 'header-item__icon--neutral-light': showBlackHeader }" />
                <div class="col">Endereços</div>
              </a>
            </li>
            <li class="mb-1">
              <a
                class="header-dropdown__item row align-items-center"
                href="/minha-conta/meus-pets"
              >
                <CaIcon name="paw" class="col-auto" />
                <div class="col">Meus pets</div>
              </a>
            </li>
            <li class="mb-1">
              <a
                class="header-dropdown__item row align-items-center"
                href="/minha-conta/avaliar-produtos"
              >
                <CaIcon name="star-half" class="col-auto" />
                <div class="col">Avaliar produtos</div>
              </a>
            </li>
            <li class="mb-1">
              <a
                class="header-dropdown__item row align-items-center"
                href="/minha-conta/carteira"
              >
                <CaIcon name="cash-payment" class="col-auto" />
                <div class="col">Meus créditos</div>
              </a>
            </li>
            <li class="mb-1">
              <a
                class="header-dropdown__item row align-items-center"
                href="https://atendimento.petlove.com.br/hc/pt-br"
              >
                <CaIcon name="headset" class="col-auto" />
                <div class="col">Atendimento</div>
              </a>
            </li>
            <li class="mb-1">
              <a
                class="header-dropdown__item row align-items-center"
                href="/indique-e-ganhe"
              >
                <CaIcon name="recommendation" class="col-auto" />
                <div class="col">Indique e Ganhe</div>
              </a>
            </li>
            <li v-if="isMounted && isLogged">
              <div
                class="header-dropdown__item row align-items-center cursor-pointer"
                @click.prevent="logout()"
              >
                <CaIcon name="exit" class="col-auto" />
                <div class="col">Sair</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <WsZipcodeModal
      v-if="showModal"
      :show-modal="showModal"
      @handler-modal="handleCaDialog()"
    />
  </header>
  <WsHeaderDesktopMenu />
</template>

<script>
import { mapActions, mapState } from 'pinia';

import { useAuthStore } from '@petlove/lion/store/auth';
import { useShipmentStore } from '@petlove/lion/store/shipment';
import { useUiStore } from '@petlove/lion/store/ui';
import { useUsersStore } from '@petlove/lion/store/users';

import { COOKIE_ZIPCODE, getCookie, } from '@petlove/lion/libs/cookies';

export default defineComponent({
  props: {
    isLogged: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
      default: () => ({}),
    },

    userPublic: {
      type: Object,
      default: () => ({}),
    },
    showSearch: {
      type: Boolean,
      default: true,
    },

    showCart: {
      type: Boolean,
      default: true,
    },

    showSubscription: {
      type: Boolean,
      default: true,
    },

    notifications: {
      type: Array,
      default: () => [],
    },

    showBlackHeader: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const { getDYChoose } = useDynamicYield();

    return {
      getDYChoose,
    }
  },

  data() {
    return {
      loading: false,
      totalItems: 0,
      zipcode: getCookie(COOKIE_ZIPCODE) || '',
      showNotifications: false,
      isMounted: false,
      showZipcodeHeaderInfo: false
	  };
  },

    computed: {
    ...mapState(useAuthStore, ['isLoadingOneTapLogin']),
    ...mapState(useShipmentStore, ['selectedZipcode', 'showModal']),
    ...mapState(useUiStore, ['getLoadingSingleSignOn']),
    ...mapState(useUsersStore, { firstPet: 'getUserFirstPet' }),

	hasZipcode() {
      return this.zipcode?.length;
    },

    hasNotifications() {
      return !!this.notifications?.length;
    },

    userFirstLetter() {
      return this.user.name && this.user.name.slice(0, 1);
    },
    showAutocompleteTestAb() {
      const campaigns = [
        STAGING_CAMPAIGNS.COMPONENT_AUTOCOMPLETE_DESKTOP,
        PRODUCTION_CAMPAIGNS.COMPONENT_AUTOCOMPLETE_DESKTOP,
      ];

      return this.isVariationRunning(campaigns);
    },
  },

  watch: {
    selectedZipcode(newPostalCode) {
      if (newPostalCode?.length === 9) this.zipcode = newPostalCode;

      this.handleAutoCompleteDialog();
    },

    showModal(value) {
      if (value) {
        this.$gtm?.dispatchEvent({
          event: 'customEvent',
          category: 'CEPNaHome',
          action: 'ViewCep',
          label: 'Novo dialog de cep visualizado',
        });
      }
    },
  },

  async mounted() {
    this.isMounted = true;

    if (!this.selectedZipcode?.length) await this.fetchDYAutocompleteAB();

    this.handleAutoCompleteDialog();
  },
  methods: {
    ...mapActions(useAuthStore, ['logout']),
    ...mapActions(useShipmentStore, ['setShowModal', 'setIsAutocompleteZipcode', 'setShowAutocompleteInfo', 'fetchIpLocation', 'isAutocompleteInfo', 'isAutocompleteZipcode']),
	  handleAutoCompleteDialog() {
      if(this.isAutocompleteZipcode() === 'true' && this.isAutocompleteInfo() === 'true') {
        this.showZipcodeHeaderInfo = true;
        setTimeout(() => {
          this.closeAutocompleteDialog();
        }, 15000);
      }
    },
    async fetchDYAutocompleteAB() {
      const payload = {
        experiment: 'DELIVERY_AUTOCOMPLETE',
        pageType: 'OTHER'
      };

      try {
        const data = await this.getDYChoose(payload);
        const showAutocomplete =
          data?.choices?.[0]?.variations?.[0]?.payload?.data?.autocomplete ||
          false;
        if (showAutocomplete) {
          await this.fetchIpLocation();

          if (window.clarity) {
            window.clarity(
              'set',
              'Testes A/B - Delivery',
              '[Delivery][A/B] - Autocomplete'
            );
          }
        }
      } catch (error) {
        this.$nuxt?.$honeybadger?.notify(error, {
          component: 'SellerInfo',
          action: 'fetchSellerInfoVariant',
          context: { payload }
        });
      }
    },
    handleCaDialog() {
      this.setShowModal(!this.showModal);
      this.zipcode = getCookie(COOKIE_ZIPCODE) || ''
    },
    closeAutocompleteDialog() {
      this.showZipcodeHeaderInfo = false;
      this.setShowAutocompleteInfo(false)
    },
    setNotifications() {
      this.clickMenuEvent('notificacoes');
      this.showNotifications = true;
    },
    clickMenuEvent(label, parentLabel = '') {
      this.$gtm.dispatchEvent({
        event_category: 'click',
        event_action: `menu:item:${label}`,
        event_label: parentLabel,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.zipcode {
  white-space: nowrap;
  margin-right: 5px;

  &--black {
    background-color: $color-black;
  }
}

.modal-container {
  .modal-close {
    color: color(primary);
    position: absolute;
    right: 5px;
    top: 5px;
    padding-top: 3px;
    background: #fff;
    font-size: 16px;
    width: 25px;
    height: 25px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }


  .modal-content {
    opacity: 1;
    background-color: #fff;
    max-width: 400px;
    width: 400px;
    display: block;
    overflow: auto;
    padding: 15px;
    position: fixed;
    left: calc(50% - 430px);
    top: 65px;
    border-radius: 12px;
    z-index: 999;
    pointer-events: auto;
    cursor: auto;
    visibility: visible;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);

    @media (max-width: 400px) {
      left: 0;
    }

    button {
      border-color: transparent;
      width: 280px;
      margin-left: 10%;
    }
  }
}

.triangle {
  width: 0px;
  height: 0px;
  margin-top: -37px;
  position: fixed;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-top: 15px solid transparent;
  border-bottom: 15px solid #fbfbfb;
  filter: drop-shadow(0 -7px 7px rgba(0, 0, 0, 0.1));
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: color(white);
  border-bottom: 1px solid color(neutral, light);
  z-index: 100;
  display: flex;
  justify-content: center;

  &--black {
    background-color: $color-black;
  }
}

.header-image {
  width: 14.2rem;
  height: 3.5rem;
}

.header-search {
  position: relative;
  margin: 0 1.2rem;
  text-align: right;
  min-width: 270px;
  &__container {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 224px;
    max-width: 470px;
    margin: 0;
  }
}

.header-item {
  $this: &;

  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 8.3rem;
  padding: 0 1.2rem;

  &:hover {
    #{$this}__text,
    #{$this}__title {
      color: color(primary);

      &--black {
        text-decoration: underline;
        color: color(white);
      }
    }

    #{$this}__subscription-icon,
    #{$this}__order-icon {
      fill: color(primary);

      &--black {
        fill: $color-neutral-light;
      }
    }

    #{$this}__bell-icon {
      &--black {
        fill: $color-neutral-main;
      }
    }
  }

  &__icon-spacing {
    width: 48px;
    &:hover {
      .header--black & {
        fill: color(white);
      }
      :not(.header--black) & {
        fill: color(primary);
      }
    }
  }

  &__icon--neutral-light {
    .header--black & {
      fill: $color-neutral-light;
    }
  }

  &__user-bg {
    background-color: color(neutral, bg);
    width: 230px;
    margin-left: 2.8rem;
    &--black {
      background-color: $color-neutral-darkest;
    }
  }

  &__user {
    height: inherit;
    width: 100%;
    margin: 0 auto;
    cursor: pointer;

    &--black {
      & > div {
        color: $color-neutral-medium;
      }
    }
  }

  &__user-icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    .header--black & {
      color: $color-neutral-medium;
    }
  }

  &__thumb {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    position: relative;
    background-color: color(neutral, lightest);
    border: 2px solid color(white);
    box-shadow: 0 0 0 1px color(neutral, medium);
    margin-right: 0.8rem;
  }

  &__thumb-letter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 1px;
  }

  &__thumb-content {
    display: inline-block;
  }

  &__title {
    font-size: fontSize(18);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 80px;
    max-width: 116px;
    transition: color 0.2s ease-out;
  }

  &__arrow-icon {
    margin-top: 6px;
  }

  &__bell-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__text {
    color: color(neutral);
    transition: color 0.2s ease-out;
    &--black {
      color: color(white);
    }
  }

  &:hover > .header-dropdown {
    opacity: 1;
    visibility: visible;
    transform: initial;
    transition: opacity, transform 0.2s ease-out;
  }
}

.header-dropdown {
  position: absolute;
  width: 260px;
  padding: 0 0.8rem;
  border-radius: 4px;
  background-color: color(white);
  list-style: none;
  top: 7.4rem;
  right: 0;
  z-index: 5;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.04);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-6px);

  &--wide {
    width: 360px;
  }

  &:hover {
    opacity: 1;
    visibility: visible;
  }

  &__item {
    padding: 1.2rem;
    background-color: color(white);
    border-radius: 4px;

    &:hover {
      background-color: color(neutral, lightest);
    }
  }
}

.header-notification {
  padding: 1.6rem;
  text-align: left;
  overflow-y: scroll;
  height: 480px;

  &__empty {
    padding: 1.6rem;
    height: 120px;
    overflow: hidden;
  }
}

@media (max-width: 1080px) {
  .header-search {
    margin: 0 0 0 1.2rem;
  }
  .header-item {
    padding: 0 1rem;
    &__user-bg {
      width: 212px;
      margin-left: 1.6rem;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
