<template>
  <footer ref="footerDesktop" :class="['footer bg-white', marginTop && 'mt-12']">
    <div class="container container--limited">
      <div class="row mb-10">
        <!-- footer box -->
        <div class="col-auto">
          <div class="footer__box pt-8 pb-8 bg-primary color-white">
            <p class="footer__box-title mb-4 font-title-s font-bold">
              Como podemos<br />
              ajudar você?
            </p>
            <p class="footer__box-description">
              Conheça mais sobre nossa empresa, políticas e tenha o melhor atendimento do mercado
              pet 😍
            </p>
            <svg
              viewBox="0 0 25 25"
              xmlns="http://www.w3.org/2000/svg"
              class="footer__box-icon svg-32"
            >
              <path
                fill="#EA534A"
                d="m6.36774.00065045c2.98976.04429275 5.51996 2.06514955 7.56856 4.69226955.4235.54259 1.3564.76959 1.7883.2353 3.9946-4.92756949 9.2766-3.7898 9.271 1.38692.3045 10.50566-15.37788 25.83656-19.2009 13.52866-1.68036-5.412-5.614107-9.0772-5.78851036-13.11341-.15779264-3.63755 2.72124036-6.7823372 6.36432036-6.72973955z"
              />
            </svg>
          </div>
        </div>

        <!-- Menu list -->
        <div class="col footer__menu-list">
          <div class="row justify-content-between">
            <!-- SAC  -->
            <div class="col-auto">
              <p class="footer__menu-list-title">Atendimento</p>
              <a
                href="https://atendimento.petlove.com.br/hc/pt-br"
                class="link footer__menu-list-link"
                title="Central de Atendimento"
                target="_blank"
                rel="noopener"
              >
                Central de atendimento
              </a>
              <a
                href="https://atendimento.petlove.com.br/hc/pt-br/articles/360033611714-Assessoria-de-Imprensa"
                class="link footer__menu-list-link"
                title="Assessoria de imprensa"
                target="_blank"
                rel="noopener"
              >
                Assessoria de imprensa
              </a>

              <hr class="footer__hr mt-5" />
            </div>

            <!-- Social and apps  -->
            <div class="col-auto">
              <p class="footer__menu-list-title">Onde estamos</p>
              <a
                href="https://www.facebook.com/petlovebr"
                title="Facebook da Petlove"
                class="mr-8"
                target="_blank"
                rel="noopener"
              >
                <img
                  :src="footerImages.facebook"
                  alt="Facebook"
                  loading="lazy"
                  width="28"
                  height="28"
                />
              </a>
              <a
                href="https://www.instagram.com/petlovebrasil"
                title="Instagram da Petlove"
                class="mr-8"
                target="_blank"
                rel="noopener"
              >
                <img
                  :src="footerImages.instagram"
                  alt="Instagram"
                  loading="lazy"
                  width="28"
                  height="28"
                />
              </a>
              <a
                href="https://www.twitter.com/petlovebr"
                title="Twitter da Petlove"
                class="mr-8"
                target="_blank"
                rel="noopener"
              >
                <img
                  :src="footerImages.twitter"
                  alt="Twitter"
                  loading="lazy"
                  width="28"
                  height="28"
                />
              </a>
              <a
                href="https://www.youtube.com/user/petlovebr"
                title="Youtube da Petlove"
                class="mr-8"
                target="_blank"
                rel="noopener"
              >
                <img
                  :src="footerImages.youtube"
                  alt="Youtube"
                  loading="lazy"
                  width="28"
                  height="28"
                />
              </a>

              <p class="footer__menu-list-title mt-7">Baixe o app</p>
              <a
                href="https://petlove.onelink.me/419198568/86e9560f"
                title="Baixar na App Store"
                class="block mr-8"
                target="_blank"
                rel="noopener"
              >
                <img
                  :src="footerImages.appleStore"
                  alt="Apple store"
                  class="footer__menu-list-img"
                  loading="lazy"
                  width="146"
                  height="43"
                />
              </a>
              <a
                href="https://petlove.onelink.me/419198568/86e9560f"
                title="Baixar na Google Play"
                class="block"
                target="_blank"
                rel="noopener"
              >
                <img
                  :src="footerImages.playStore"
                  alt="Play store"
                  class="footer__menu-list-img"
                  loading="lazy"
                  width="146"
                  height="43"
                />
              </a>
            </div>

            <!-- Policy  -->
            <div class="col-auto">
              <p class="footer__menu-list-title">Políticas</p>
              <a
                href="https://atendimento.petlove.com.br/hc/pt-br/articles/360034111973-Pol%C3%ADtica-de-Privacidade"
                class="link footer__menu-list-link"
                title="Aviso de privacidade"
                target="_blank"
                rel="noopener"
              >
                Aviso de privacidade
              </a>
              <a
                href="https://atendimento.petlove.com.br/hc/pt-br/articles/1260807418629-Pol%C3%ADtica-de-Cookies"
                class="link footer__menu-list-link"
                title="Política de Cookies"
                target="_blank"
                rel="noopener"
              >
                Política de Cookies
              </a>
              <a
                href="https://atendimento.petlove.com.br/hc/pt-br/articles/360034115513-Pol%C3%ADtica-de-Entrega-Troca-e-Devolu%C3%A7%C3%A3o-de-Produtos"
                class="link footer__menu-list-link"
                title="Política de entrega e devolução"
                target="_blank"
                rel="noopener"
              >
                Política de entrega e devolução
              </a>
              <a
                href="https://atendimento.petlove.com.br/hc/pt-br/articles/360033609874-Pol%C3%ADtica-de-Compra"
                class="link footer__menu-list-link"
                title="Política de compra"
                target="_blank"
                rel="noopener"
              >
                Política de compra
              </a>
              <a
                href="https://atendimento.petlove.com.br/hc/pt-br/articles/360034117913-Pol%C3%ADtica-de-white-hat"
                class="link footer__menu-list-link"
                title="Política de white hat"
                target="_blank"
                rel="noopener"
              >
                Política de white hat
              </a>
            </div>

            <!-- Institutional -->
            <div class="col-auto">
              <p class="footer__menu-list-title">Institucional</p>
              <a
                href="https://www.petlove.com.br/sustentabilidade"
                class="link footer__menu-list-link"
                title="Sustentabilidade"
                target="_blank"
                rel="noopener"
              >
                Sustentabilidade
              </a>
              <a
                href="https://atendimento.petlove.com.br/hc/pt-br/articles/360034103673-Empresa"
                class="link footer__menu-list-link"
                title="Empresa"
                target="_blank"
                rel="noopener"
              >
                Empresa
              </a>
              <a
                href="https://atendimento.petlove.com.br/hc/pt-br/articles/360033606354-Termos-e-Condi%C3%A7%C3%B5es-de-Uso"
                class="link footer__menu-list-link"
                title="Termos e condições de uso"
                target="_blank"
                rel="noopener"
              >
                Termos e condições de uso
              </a>
              <a
                href="https://canaldeetica.com.br/petlove/"
                class="link footer__menu-list-link"
                title="Canal de ética e conduta"
                target="_blank"
                rel="noopener"
              >
                Canal de ética e conduta
              </a>
              <a
                href="https://petlove.gupy.io"
                class="link footer__menu-list-link"
                title="Trabalhe conosco"
                target="_blank"
                rel="noopener"
              >
                Trabalhe conosco
              </a>
              <a
                href="https://materiais.petlove.com.br/franquia-cadastro"
                class="link footer__menu-list-link"
                title="Franquia Petlove | Cadastro"
                target="_blank"
                rel="noopener"
              >
                Franquia Petlove | Cadastro
              </a>
              <a
                href="https://www.petlove.com.br/lojas-fisicas"
                class="link footer__menu-list-link"
                title="Lojas Físicas"
                target="_blank"
                rel="noopener"
              >
                Lojas Físicas
              </a>
              <a
                href="https://materiais.petlove.com.br/alugue-seu-ponto-comercial"
                class="link footer__menu-list-link"
                title="Alugue o seu ponto comercial para a Petlove"
                target="_blank"
                rel="noopener"
              >
                Alugue o seu imóvel
              </a>
              <a
                href="https://materiais.petlove.com.br/marketplace"
                class="link footer__menu-list-link"
                title="Venda os seus produtos na Petlove"
                target="_blank"
                rel="noopener"
              >
                Venda com a Petlove
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-9">
        <div class="footer__links row">
          <div
            v-for="(footerLink, footerIndex) in links"
            :key="footerIndex"
            class="footer__links__wrapper col-2 pb-5"
          >
            <p class="footer__menu-list-title mb-3">{{ footerLink.title }}</p>
            <a
              v-for="(link, linkIndex) in footerLink.links"
              v-show="isLinkVisible(footerIndex, linkIndex)"
              :key="linkIndex"
              :href="link.url"
              :title="link.title"
              target="_blank"
              rel="noopener"
              class="footer__menu-list-link"
              >{{ link.title }}</a
            >
            <div
              v-if="footerLink.links.length > visibleLinks"
              v-show="!expandedColumns[footerIndex]"
              class="flex my-5"
              @click="loadMoreLinks(footerIndex)"
            >
              <button class="font-body-s font-medium color-primary cursor-pointer mr-3">
                {{ buttonText }}
              </button>
              <CaIcon :name="iconName" color="primary" :size="20" clickable />
            </div>
          </div>
        </div>
      </div>

      <!-- Payment and seals -->
      <div class="footer__seals row mb-6">
        <div class="col">
          <p class="mb-6">Formas de pagamento</p>
          <img
            :src="footerImages.paymentMethods"
            alt="Boleto, cartões de crédito e pix"
            loading="lazy"
            width="400"
            height="24"
            class="footer__payments-image"
          />
        </div>

        <div class="footer__seals-images">
          <a
            href="https://www.petlove.com.br/dicas/selo-ra1000"
            class="mr-7"
            target="_blank"
            rel="noopener"
          >
            <img
              class="footer__seals-images-item"
              :src="footerImages.raCertificated"
              alt="Selo RA1000"
              loading="lazy"
              width="95"
              height="58"
            />
          </a>
          <a href="http://www.ebit.com.br/3160" class="mr-7" target="_blank" rel="noopener">
            <img
              class="footer__seals-images-item"
              :src="footerImages.ebitDiamond"
              alt="Selo do Ebit Diamante"
              loading="lazy"
              width="62"
              height="64"
            />
          </a>
          <a
            href="http://www.ebit.com.br/premio-ebit/2017"
            class="mr-7"
            target="_blank"
            rel="noopener"
          >
            <img
              class="footer__seals-images-item"
              :src="footerImages.ebitAward"
              alt="Selo do Prêmio Ebit"
              loading="lazy"
              width="60"
              height="64"
            />
          </a>
          <a
            href="https://www.google.com/transparencyreport/safebrowsing/diagnostic/?hl=pt-BR#url=petlove.com.br"
            target="_blank"
            rel="noopener"
          >
            <img
              class="footer__seals-images-item"
              :src="footerImages.googleSafeBrowser"
              alt="Selo do Google Safe Browser"
              loading="lazy"
              width="105"
              height="34"
            />
          </a>
        </div>
      </div>

      <!-- Copyright -->
      <div class="footer__copyright row">
        <div class="col">
          <p class="font-text-m">
            Petsupermarket Comércio de Produtos para Animais S.A<br />
            CNPJ: 10.864.846/0001-23<br />
            Av. das Nações Unidas 12901 - 11º andar, Brooklin Paulista, São Paulo - SP, 04578-910
          </p>
        </div>
        <div>
          <p class="font-13">
            Compra segura - A Petlove garante segurança para suas informações pessoais e
            financeiras<br />
            Copyright 2020 © Todos os direitos reservados à Petlove
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import links from '@petlove/lion/libs/_mocks_/footer-links.js';

export default {
  props: {
    store: {
      type: Object,
      default: () => ({})
    },
    marginTop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      links,
      footerImages: {
        partnership: 'https://www.petlove.com.br/images/whitelabel/banners/partnership.svg',
        googleSafeBrowser:
          'https://www.petlove.com.br/static/uploads/banner_image/image/6021/selo-google-safe.png',
        ebitAward:
          'https://www.petlove.com.br/static/uploads/banner_image/image/6022/selo-premio-ebit.png',
        ebitDiamond:
          'https://www.petlove.com.br/static/uploads/banner_image/image/6020/selo-diamante-ebit.png',
        raCertificated:
          'https://www.petlove.com.br/static/uploads/banner_image/image/10622/ra_1000_img.png',
        paymentMethods: 'https://www.petlove.com.br/static/uploads/images/payment/methods.png',
        playStore:
          'https://www.petlove.com.br/static/uploads/banner_image/image/17755/google_stroke.png',
        appleStore:
          'https://www.petlove.com.br/static/uploads/banner_image/image/17754/apple_stroke.png',
        youtube: 'https://www.petlove.com.br/static/uploads/images/social/icon_youtube.svg',
        twitter: 'https://www.petlove.com.br/static/uploads/caramelo/twitter.svg',
        instagram: 'https://www.petlove.com.br/static/uploads/images/social/icon_instagram.svg',
        facebook: 'https://www.petlove.com.br/static/uploads/images/social/icon_facebook.svg'
      },
      buttonText: 'Expandir',
      iconName: 'chevron-bottom',
      visibleLinks: 11,
      expandedColumns: {}
    };
  },
  methods: {
    loadMoreLinks(footerIndex) {
      this.expandedColumns[footerIndex] = true;
    },
    isLinkVisible(footerIndex, linkIndex) {
      return this.expandedColumns[footerIndex] || linkIndex < 11;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background-color: color(white);
  border-top: 1px solid color(neutral, light);
  padding: 5.2rem 0 2rem;
  bottom: 0;

  &__box {
    width: 260px;
    margin-right: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: 4px;

    &-title {
      color: color(white);
      line-height: 1.2;
    }

    &-description {
      color: color(white);
      line-height: 1.7;
      word-break: break-word;
    }

    &-icon {
      position: absolute;
      top: 9.4rem;
      right: 1rem;
    }

    &-svg {
      height: 28px;
      width: 28px;
    }

    @media (min-width: 1366px) {
      width: 360px;
      height: 214px;
      padding: 3.2rem 4.8rem;
      margin-right: 4rem;

      &-icon {
        top: 9rem;
        right: 3.1rem;
      }
    }
  }

  &__menu-list {
    &-title {
      font-weight: bold;
      color: color(primary);
      margin-bottom: 0.8rem;
      white-space: nowrap;
    }

    &-link {
      display: block;
      margin-bottom: 0.8rem;
      text-decoration: none;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        color: color(primary);
        text-decoration: underline;
      }
    }

    &-img {
      max-width: 146px;
      aspect-ratio: 16 / 9;
      object-fit: contain;
    }

    &__hr {
      border-top: 1px dashed color(neutral, medium);
      max-width: 170px;
    }
  }

  &__links {
    @media (min-width: 1025px) {
      display: flex;
      justify-content: space-around;
    }
    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(3, 25%);
      justify-content: space-around;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 25%);
      justify-content: space-evenly;
    }
    &__wrapper {
      @media(max-width: 1024px) {
        max-width: fit-content;
      }
    }
  }


  &__payments-image {
    max-width: 400px;
    aspect-ratio: 16 / 9;
    object-fit: contain;
  }

  &__seals {
    &-images {
      display: inline-flex;
      align-items: center;

      &-item {
        aspect-ratio: 4 / 3;
        object-fit: contain;
      }
    }
  }

  &__copyright {
    color: color(neutral, medium);
    line-height: 1.7;
  }

  &__partnership {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid color(neutral, light);
  }
}
</style>
